@import "variable";

.about-us {
  padding: vw(100px) vw(0px) vw(87px);
  max-width: 98%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;

  h2 {
    margin: 0;
    font-size: vw(20px);
    text-transform: uppercase;
    line-height: 1.4;
    font-weight: 500;
    color: #B6B6B7;
    text-align: left;
    font-family: Rubik;
  }

  &__image {
    margin: 0 auto;
    background-color: #fff;
    padding: vw(75px) vw(280px);
    max-width: 1134px;
    box-sizing: border-box;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__text {
    font-size: vw(14px);
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    font-family: Rubik;
    color: #E1E1E1;
    margin-top: vw(23px);
  }

  &--uppercase {
    text-transform: uppercase;
    line-height: 1.35;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 45.7%;
    margin: vw(73px) auto 0;
  }
  &__left-container {
    width: 25%;
  }

  &__right-container {
    width: 65%;
    .about-us__text:first-child {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 991px){
  .about-us {
    max-width: 100%;
    padding: vwm(20px) vwm(20px) vwm(40px);
    &__container {
      flex-direction: column;
      padding: 0;
      width: 100%;
      margin-top: vwm(25px);
    }
    &__image {
      padding: vwm(13px);
    }
    &__left-container {
      width: 100%;
    }
    &__right-container {
      width: 100%;
    }
    h2 {
      font-size: vwm(17px);
    }
    &--uppercase {
      margin-top: vwm(12px);
      font-size: vwm(12px);
    }
    &__text {
      font-size: vwm(11px);
      margin-top: vwm(20px);
    }
  }
}