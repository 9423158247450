@import "variable";
.hero {
  padding-top: vw(40px);
  padding-bottom: vw(30px);
  &__more {
    display: flex;
    flex-direction: column;
    font-size: vw(18px);
    text-decoration: none;
    color: #000;
    font-family: Rubik;
    font-weight: 600;
    line-height: 1.22;
    img {
      width: 12px;
      margin: vw(18px) auto;
    }
  }
  &__image {
    width: vw(670px);
    height: vw(400px);
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    img {
      position: absolute;
      left: 0;
    }
    .static1 {
      z-index: 2;
      bottom: vw(62px);
      left: vw(138px);
      width: vw(497px);
    }
    .static2 {
      width: vw(670px);
    }
  }
  &__parallax {
    height: 100%;
    width: 100%;
    .parallax1 {
      margin-left: vw(126px);
      width: vw(407px);
    }
    .parallax2 {
      margin-top: vw(145px);
      margin-left: vw(80px);
      width: vw(484px);
      z-index: 2;
    }
    .parallax3 {
      margin-top: vw(225px);
      margin-left: vw(80px);
      width: vw(591px);
    }
  }
}

@media screen and (max-width: 991px){
  .hero {
    padding-top: vwm(10px);
    padding-bottom: vwm(30px);
  }
  a.logo {
    width: vwm(120px);
    display: block;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .hero__image {
    margin-top: vwm(20px);
    padding: 0 vwm(25px);
    box-sizing: border-box;
    width: 100%;
    height: auto;
    img {
      position: relative;
      width: 100%;
    }
  }

  .hero__more {
    font-size: vwm(17px);
    img {
      margin-top: vwm(12px);
    }
  }
}

