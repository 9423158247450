@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-DemiBold.eot');
    src: local('Oswald DemiBold'), local('Oswald-DemiBold'),
        url('./Oswald-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-DemiBold.woff2') format('woff2'),
        url('./Oswald-DemiBold.woff') format('woff'),
        url('./Oswald-DemiBold.ttf') format('truetype'),
        url('./Oswald-DemiBold.svg#Oswald-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-BoldItalic.eot');
    src: local('Oswald BoldItalic'), local('Oswald-BoldItalic'),
        url('./Oswald-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-BoldItalic.woff2') format('woff2'),
        url('./Oswald-BoldItalic.woff') format('woff'),
        url('./Oswald-BoldItalic.ttf') format('truetype'),
        url('./Oswald-BoldItalic.svg#Oswald-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Bold.eot');
    src: local('Oswald Bold'), local('Oswald-Bold'),
        url('./Oswald-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Bold.woff2') format('woff2'),
        url('./Oswald-Bold.woff') format('woff'),
        url('./Oswald-Bold.ttf') format('truetype'),
        url('./Oswald-Bold.svg#Oswald-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-ExtraLight.eot');
    src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
        url('./Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-ExtraLight.woff2') format('woff2'),
        url('./Oswald-ExtraLight.woff') format('woff'),
        url('./Oswald-ExtraLight.ttf') format('truetype'),
        url('./Oswald-ExtraLight.svg#Oswald-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Extra-LightItalic.eot');
    src: local('Oswald Extra-LightItalic'), local('Oswald-Extra-LightItalic'),
        url('./Oswald-Extra-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Extra-LightItalic.woff2') format('woff2'),
        url('./Oswald-Extra-LightItalic.woff') format('woff'),
        url('./Oswald-Extra-LightItalic.ttf') format('truetype'),
        url('./Oswald-Extra-LightItalic.svg#Oswald-Extra-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Demi-BoldItalic.eot');
    src: local('Oswald Demi-BoldItalic'), local('Oswald-Demi-BoldItalic'),
        url('./Oswald-Demi-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Demi-BoldItalic.woff2') format('woff2'),
        url('./Oswald-Demi-BoldItalic.woff') format('woff'),
        url('./Oswald-Demi-BoldItalic.ttf') format('truetype'),
        url('./Oswald-Demi-BoldItalic.svg#Oswald-Demi-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Heavy.eot');
    src: local('Oswald Heavy'), local('Oswald-Heavy'),
        url('./Oswald-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Heavy.woff2') format('woff2'),
        url('./Oswald-Heavy.woff') format('woff'),
        url('./Oswald-Heavy.ttf') format('truetype'),
        url('./Oswald-Heavy.svg#Oswald-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Light.eot');
    src: local('Oswald Light'), local('Oswald-Light'),
        url('./Oswald-Light.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Light.woff2') format('woff2'),
        url('./Oswald-Light.woff') format('woff'),
        url('./Oswald-Light.ttf') format('truetype'),
        url('./Oswald-Light.svg#Oswald-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-HeavyItalic.eot');
    src: local('Oswald HeavyItalic'), local('Oswald-HeavyItalic'),
        url('./Oswald-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-HeavyItalic.woff2') format('woff2'),
        url('./Oswald-HeavyItalic.woff') format('woff'),
        url('./Oswald-HeavyItalic.ttf') format('truetype'),
        url('./Oswald-HeavyItalic.svg#Oswald-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-LightItalic.eot');
    src: local('Oswald LightItalic'), local('Oswald-LightItalic'),
        url('./Oswald-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-LightItalic.woff2') format('woff2'),
        url('./Oswald-LightItalic.woff') format('woff'),
        url('./Oswald-LightItalic.ttf') format('truetype'),
        url('./Oswald-LightItalic.svg#Oswald-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-MediumItalic.eot');
    src: local('Oswald MediumItalic'), local('Oswald-MediumItalic'),
        url('./Oswald-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-MediumItalic.woff2') format('woff2'),
        url('./Oswald-MediumItalic.woff') format('woff'),
        url('./Oswald-MediumItalic.ttf') format('truetype'),
        url('./Oswald-MediumItalic.svg#Oswald-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Medium.eot');
    src: local('Oswald Medium'), local('Oswald-Medium'),
        url('./Oswald-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Medium.woff2') format('woff2'),
        url('./Oswald-Medium.woff') format('woff'),
        url('./Oswald-Medium.ttf') format('truetype'),
        url('./Oswald-Medium.svg#Oswald-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-Regular.eot');
    src: local('Oswald Regular'), local('Oswald-Regular'),
        url('./Oswald-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-Regular.woff2') format('woff2'),
        url('./Oswald-Regular.woff') format('woff'),
        url('./Oswald-Regular.ttf') format('truetype'),
        url('./Oswald-Regular.svg#Oswald-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald Stencil';
    src: url('./OswaldStencil-Bold.eot');
    src: local('Oswald Stencil Bold'), local('OswaldStencil-Bold'),
        url('./OswaldStencil-Bold.eot?#iefix') format('embedded-opentype'),
        url('./OswaldStencil-Bold.woff2') format('woff2'),
        url('./OswaldStencil-Bold.woff') format('woff'),
        url('./OswaldStencil-Bold.ttf') format('truetype'),
        url('./OswaldStencil-Bold.svg#OswaldStencil-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./Oswald-RegularItalic.eot');
    src: local('Oswald RegularItalic'), local('Oswald-RegularItalic'),
        url('./Oswald-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./Oswald-RegularItalic.woff2') format('woff2'),
        url('./Oswald-RegularItalic.woff') format('woff'),
        url('./Oswald-RegularItalic.ttf') format('truetype'),
        url('./Oswald-RegularItalic.svg#Oswald-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Black.eot');
  src: local('Inter Black'), local('Inter-Black'),
  url('./Inter-Black.eot?#iefix') format('embedded-opentype'),
  url('./Inter-Black.woff2') format('woff2'),
  url('./Inter-Black.woff') format('woff'),
  url('./Inter-Black.ttf') format('truetype'),
  url('./Inter-Black.svg#Inter-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Bold.eot');
  src: local('Inter Bold'), local('Inter-Bold'),
  url('./Inter-Bold.eot?#iefix') format('embedded-opentype'),
  url('./Inter-Bold.woff2') format('woff2'),
  url('./Inter-Bold.woff') format('woff'),
  url('./Inter-Bold.ttf') format('truetype'),
  url('./Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-ExtraBold.eot');
  src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
  url('./Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./Inter-ExtraBold.woff2') format('woff2'),
  url('./Inter-ExtraBold.woff') format('woff'),
  url('./Inter-ExtraBold.ttf') format('truetype'),
  url('./Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-ExtraLight.eot');
  src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
  url('./Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./Inter-ExtraLight.woff2') format('woff2'),
  url('./Inter-ExtraLight.woff') format('woff'),
  url('./Inter-ExtraLight.ttf') format('truetype'),
  url('./Inter-ExtraLight.svg#Inter-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Light.eot');
  src: local('Inter Light'), local('Inter-Light'),
  url('./Inter-Light.eot?#iefix') format('embedded-opentype'),
  url('./Inter-Light.woff2') format('woff2'),
  url('./Inter-Light.woff') format('woff'),
  url('./Inter-Light.ttf') format('truetype'),
  url('./Inter-Light.svg#Inter-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Medium.eot');
  src: local('Inter Medium'), local('Inter-Medium'),
  url('./Inter-Medium.eot?#iefix') format('embedded-opentype'),
  url('./Inter-Medium.woff2') format('woff2'),
  url('./Inter-Medium.woff') format('woff'),
  url('./Inter-Medium.ttf') format('truetype'),
  url('./Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'),
  url('./Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('./Inter-Regular.woff2') format('woff2'),
  url('./Inter-Regular.woff') format('woff'),
  url('./Inter-Regular.ttf') format('truetype'),
  url('./Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-SemiBold.eot');
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
  url('./Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./Inter-SemiBold.woff2') format('woff2'),
  url('./Inter-SemiBold.woff') format('woff'),
  url('./Inter-SemiBold.ttf') format('truetype'),
  url('./Inter-SemiBold.svg#Inter-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Thin.eot');
  src: local('Inter Thin'), local('Inter-Thin'),
  url('./Inter-Thin.eot?#iefix') format('embedded-opentype'),
  url('./Inter-Thin.woff2') format('woff2'),
  url('./Inter-Thin.woff') format('woff'),
  url('./Inter-Thin.ttf') format('truetype'),
  url('./Inter-Thin.svg#Inter-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-BlackItalic.eot');
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'),
  url('./Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-BlackItalic.woff2') format('woff2'),
  url('./Rubik-BlackItalic.woff') format('woff'),
  url('./Rubik-BlackItalic.ttf') format('truetype'),
  url('./Rubik-BlackItalic.svg#Rubik-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-Black.eot');
  src: local('Rubik Black'), local('Rubik-Black'),
  url('./Rubik-Black.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-Black.woff2') format('woff2'),
  url('./Rubik-Black.woff') format('woff'),
  url('./Rubik-Black.ttf') format('truetype'),
  url('./Rubik-Black.svg#Rubik-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-Bold.eot');
  src: local('Rubik Bold'), local('Rubik-Bold'),
  url('./Rubik-Bold.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-Bold.woff2') format('woff2'),
  url('./Rubik-Bold.woff') format('woff'),
  url('./Rubik-Bold.ttf') format('truetype'),
  url('./Rubik-Bold.svg#Rubik-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-Italic.eot');
  src: local('Rubik Italic'), local('Rubik-Italic'),
  url('./Rubik-Italic.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-Italic.woff2') format('woff2'),
  url('./Rubik-Italic.woff') format('woff'),
  url('./Rubik-Italic.ttf') format('truetype'),
  url('./Rubik-Italic.svg#Rubik-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-BoldItalic.eot');
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
  url('./Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-BoldItalic.woff2') format('woff2'),
  url('./Rubik-BoldItalic.woff') format('woff'),
  url('./Rubik-BoldItalic.ttf') format('truetype'),
  url('./Rubik-BoldItalic.svg#Rubik-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-LightItalic.eot');
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'),
  url('./Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-LightItalic.woff2') format('woff2'),
  url('./Rubik-LightItalic.woff') format('woff'),
  url('./Rubik-LightItalic.ttf') format('truetype'),
  url('./Rubik-LightItalic.svg#Rubik-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-Light.eot');
  src: local('Rubik Light'), local('Rubik-Light'),
  url('./Rubik-Light.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-Light.woff2') format('woff2'),
  url('./Rubik-Light.woff') format('woff'),
  url('./Rubik-Light.ttf') format('truetype'),
  url('./Rubik-Light.svg#Rubik-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-Medium.eot');
  src: local('Rubik Medium'), local('Rubik-Medium'),
  url('./Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-Medium.woff2') format('woff2'),
  url('./Rubik-Medium.woff') format('woff'),
  url('./Rubik-Medium.ttf') format('truetype'),
  url('./Rubik-Medium.svg#Rubik-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-MediumItalic.eot');
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'),
  url('./Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-MediumItalic.woff2') format('woff2'),
  url('./Rubik-MediumItalic.woff') format('woff'),
  url('./Rubik-MediumItalic.ttf') format('truetype'),
  url('./Rubik-MediumItalic.svg#Rubik-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik Mono One';
  src: url('./RubikMonoOne-Regular.eot');
  src: local('Rubik Mono One Regular'), local('RubikMonoOne-Regular'),
  url('./RubikMonoOne-Regular.eot?#iefix') format('embedded-opentype'),
  url('./RubikMonoOne-Regular.woff2') format('woff2'),
  url('./RubikMonoOne-Regular.woff') format('woff'),
  url('./RubikMonoOne-Regular.ttf') format('truetype'),
  url('./RubikMonoOne-Regular.svg#RubikMonoOne-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik-Regular.eot');
  src: local('Rubik'), local('Rubik-Regular'),
  url('./Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('./Rubik-Regular.woff2') format('woff2'),
  url('./Rubik-Regular.woff') format('woff'),
  url('./Rubik-Regular.ttf') format('truetype'),
  url('./Rubik-Regular.svg#Rubik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}