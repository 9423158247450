/*
 * Главный файл стилей.
 * Все паршалы подключаются сюда.
 */

// Стили для файла с примером HTML-фрагмента
@import "../fonts/stylesheet.css";
@import "./common";
@import './hero';
@import "./about-us";
@import "investment-excellence";
@import "investment-news";
@import "./contact-us";
@import "./footer";