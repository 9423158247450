@import "variable";
footer {
  padding: vw(45px) vw(60px);
  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .to-top {
    width: vw(30px);
    height: vw(30px);
    display: flex;
    background: #000;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: vw(25px);
  }
  .footer-description {
    text-transform: uppercase;
  }
  .disclamer {
    margin-left: auto;
    font-size: vw(14px);
    font-weight: 500;
    text-transform: uppercase;
  }
  .footer__link {
    text-decoration: none;
    color: #000;
    font-size: vw(18px);
    font-weight: 600;
    margin-right: vw(30px);
  }
}

@media screen and (max-width: 991px){
  footer {
    position: relative;
    padding: vwm(50px) vwm(20px);
    overflow: hidden;
    .wrapper {
      flex-direction: column;
    }
    .to-top {
      position: absolute;
      left: 20px;
      margin-top: 10px;
      width: vwm(30px);
      height: vwm(30px);
    }
    .disclamer {
      text-align: left;
      padding-right: vwm(40px);
      box-sizing: border-box;
      text-transform: uppercase;
      width: 100%;
      font-size: vwm(12px);
      margin-left: vwm(100px);
    }
    .footer-description {
      text-align: left;
      width: 95%;
      margin-left: vwm(90px);
      margin-top: vwm(10px);
    }
    .footer__link {
      display: block;
      text-align: left;
      font-size: vwm(16px);
      margin-bottom: vwm(5px);
    }
  }
}