@import "variable";
.investment-news {
  padding-bottom: vw(120px);
}
.investment-news__list {
  column-count: 3;
  list-style-type: none;
  margin-top: vw(64px);
  padding: 0 vw(60px);
}

.tradingview-widget-container {
  width: 100% !important;
}

.tradingview-widget-container iframe {
  width: 100% !important;
}

.read-more {
  color: #000;
  text-decoration: none;
  border: 1px solid #000;
  padding: 10px 15px;
  margin: 50px auto;
  display: block;
  width: 120px;
}

@media screen and (max-width: 991px){
  .investment-news {
    padding: 0 vwm(20px) vwm(20px);
    &__list {
      flex-direction: column;
      column-count: 1;
      padding: 0;
    }
  }
}
