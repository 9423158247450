@import "variable";
.contact-us {
  padding: vw(100px) vw(0px) vw(100px);
  max-width: 98%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;

  .wrapper {
    display: flex;
    padding: 0;
    justify-content: flex-end;
  }
  &__right-container {
    margin-left: vw(73px);
    padding-right: vw(20px);
  }
  h2 {
    font-size: vw(20px);
    font-weight: normal;
    text-transform: uppercase;
    color: #B6B6B7;
    text-align: left;
    font-family: Rubik;
    font-weight: 500;
    margin-top: 0;
  }

  p {
    font-size: vw(14px);
    text-align: left;
  }

  .cards {
    width: 100%;
  }

  &__list {
    list-style-type: none;
    text-align: left;
    padding: 0;
    margin: 0;
    .contact-item {
      p {
        color: #B6B6B7;
        font-size: vw(14px);
      }
    }
    li {
      border-top: 1px solid #dcdbdb42;
      margin-top: 50px;

      &:first-child {
        margin-top: 0;
      }
      .linkedin {
        text-decoration: underline;
      }
    }

    li a {
      text-decoration: none;
      color: #fff;
      font-size: vw(60px);
      line-height: 1.1;
      font-weight: 700;
      font-family: Rubik;
    }
  }

  &__map {
    margin-top: vw(50px);

    img {
      width: 100%;
    }
  }

  &__left-container {
    text-transform: uppercase;
    background: url(../images/mechanic.svg) no-repeat;
    background-position: 0 bottom;
    background-size: contain;
    & > p {
      line-height: 1.4;
    }
  }
  &__right-container {
    width: 53%;
  }
  .contact-us__card {
    display: flex;
    align-items: center;
    margin-top: vw(43px);
  }

  &__container-text {
    margin-left: vw(20px);

    h3 {
      margin: 0;
      font-size: vw(14px);
    }

    p {
      margin: 0;
    }
    .description {
      color: #B6B6B7;
      margin-top: 5px;
    }
  }
  &__image-wrapper {
    width: vw(46px);
    height: vw(46px);
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}


@media screen and (max-width: 991px){
  .contact-us {
    max-width: 100%;
    padding: vwm(30px) vwm(20px) 0;
    box-sizing: border-box;
    h2 {
      font-size: vwm(17px);
      font-family: Rubik;
    }
    &__left-container {
      background: none;
      & > p {
        font-size: vwm(13px);
        br {
          display: none;
        }
      }
    }
    .contact-us__card {
      margin-top: vwm(20px);
    }
    &__container-text {
      margin-left: vwm(15px);
      h3 {
        font-size: vwm(11px);
        font-weight: 700;
      }
      .description {
        font-size: vwm(10px);
      }
    }
    .wrapper {
      flex-direction: column;
    }
    &__list {
      margin-top: vwm(20px);
      li {
        margin-top: vwm(30px);
        padding-top: vwm(20px);
        a {
          font-size: vwm(30px);
          margin-top: vwm(12px);
          display: block;
          line-height: 1.2;
        }
      }
      .contact-item {
        p {
          font-size: vwm(15px);
        }
      }
    }
    p {
      font-size: vwm(12.2px);
      margin: 0;
    }
    &__mobile-img {
      margin-top: vwm(30px);
      text-align: center;
      padding-bottom: vwm(30px);
    }
    &__right-container {
      margin-left: 0;
      width: 100%;
    }
    &__map {
      margin-top: vwm(25px);
    }
    &__image-wrapper {
      width: vwm(38px);
      height: vwm(38px);
    }
  }

}