@import "variable";
.investment-excellence {
  padding: vw(70px) 0 vw(55px);
  &__list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 vw(60px);
    margin-top: vw(60px);
    h3 {
      font-weight: 600;
      text-transform: uppercase;
      text-align: left;
      font-family: Rubik;
      margin-top: vw(34px);
      font-size: vw(20px);
      line-height: 1.3;
      margin-bottom: vw(10px);
    }
    p {
      font-size: vw(14px);
      line-height: 1.4;
      text-align: left;
      font-weight: 400;
    }
  }
  &__list-item {
    border: 1px solid #DCDBDB;
    width: 32%;
    padding: vw(45px) vw(50px) vw(50px) vw(50px);
    box-sizing: border-box;
    img {
      flex-grow: 1;
      object-fit: scale-down;
      width: 100%;
    }
  }
}
@media screen and (max-width: 991px){
  .investment-excellence {
    &__list {
      flex-direction: column;
      padding: 0 vwm(20px) vwm(10px);
      p {
        font-size: vwm(11.8px);
      }
    }
    &__list-item {
      width: 100%;
      padding: vwm(15px);
      margin-top: vwm(20px);
      &:first-child{
        margin-top: 0;
      }
      img {
        width: 100%;
      }
    }
    h3 {
      font-size: vwm(15px);
      margin-top: vwm(22px);
      br {
        display: none;
      }
    }
  }
}