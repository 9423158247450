@import "variable";
body {
  margin: 0;
  padding: 0;
  font-family: Rubik;
}
h1 {
  font-family: Rubik;
  font-weight: Bold;
  font-size: vw(60px);
  line-height: 1.1;
  padding: 0 vw(557px);
  margin-top: vw(60px);
}
h2 {
  font-family: Rubik;
  font-weight: Bold;
  font-size: vw(60px);
  line-height: 1.1;
}
.wrapper {
  width: vw(1920px - 42*2);
  margin: 0 auto;
  text-align: center;
}
.mobile {
  display: none !important;
}
.desktop {
  display: block !important;
}
@media screen and (max-width: 991px){
  .wrapper {
    width: 100%;
  }
  .hero__image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: vwm(30px);
    padding: 0;
    margin-top: vwm(30px);
  }
  h2 {
    font-size: vwm(32px);
    line-height: 1.25;
  }
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
}